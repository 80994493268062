import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_BANNER_DIALOG } from "../../store/banner/types";

//action
import $ from "jquery";
import noImage from "../../assets/images/noImage.png";
import { createNewBanner, editBanner } from "../../store/banner/action";
import { baseURL } from "../../util/Config";

const BannerDialog = (props) => {
  const dispatch = useDispatch();

  const { dialog: open, dialogData } = useSelector((state) => state.banner);

  const [mongoId, setMongoId] = useState("");
  const [link, setLink] = useState("");
  const [imageData, setImageData] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [bannerValue, setBannerValue] = useState();
  const [walletImage, setWalletImage] = useState();
  const [walletImagePath, setWalletImagePath] = useState();



  const [errors, setError] = useState({
    link: "",
    image: "",
    banerType : "",
  });
  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", noImage);
    });
  });
  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData._id);
      setLink(dialogData.URL);
      setImagePath(baseURL + dialogData.image);
    }
  }, [dialogData]);

  useEffect(
    () => () => {
      setError({
        link: "",
        image: "",
        banerType : "",
      });
      setMongoId("");
      setLink("");
      setImageData(null);
      setImagePath(null);
    
    },
    [open]
  );



  useEffect(() => {
    window.onbeforeunload = closePopup();
  }, []);

  const HandleInputImage = (e) => {
    setError({ image: "" });
    if (e.target.files[0]) {
      setImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const closePopup = () => {
    dispatch({ type: CLOSE_BANNER_DIALOG });
  };

  const isValidURL = (url) => {
    if (typeof url !== "string") {
      return false; // Make sure url is a string
    }

    const urlRegex = /^(ftp|http|https):\/[^ "]+$/;
    return urlRegex.test(url) || url?.startsWith("blob:");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};

    if (!bannerValue) {
      errors.banerType = "Please select a banner type";
    }

    if (bannerValue === "3") {
      if (!link || !isValidURL(link)) {
        errors.link = "Valid Link is Required!";
      }
    }


    if ((!imageData && !mongoId)) {
      errors.image = "Banner Photo is Required!";
    }

    // If errors are present, stop submission
    if (Object.keys(errors).length > 0) {
      return setError(errors);
    }

    const formData = new FormData();
    formData.append("image", imageData);
    formData.append("URL", link);
    formData.append("bannerType", bannerValue);

    if (mongoId) {
      props.editBanner(mongoId, formData);
    } else {
      props.createNewBanner(formData);
    }
  };

  const banerType = [
    {
      name: "Open Wallet",
      value: 1,
    },
    {
      name: "Open CoinSeller",
      value: 2,
    },
    {
      name: "Url",
      value: 3,
    },
  ];



  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Banner </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <label className="text-gray">Select a Banner Type</label>
                <select
                  class="form-select form-control mt-4"
                  aria-label="Default select example"
                
                  value={bannerValue}
                  onChange={(e) => {
                    setBannerValue(e.target.value);

                    if (!e.target.value) {
                      return setError({
                        ...errors,
                        banerType: "Please select a banner type!",
                      });
                    }
                     else {
                      return setError({
                        ...errors,
                        banerType: "",
                      });
                    }
                  }}
                >
                  <option selected>Select__BannerType</option>
                  {banerType.map((bannerType) => {
                    return (
                      <option value={bannerType.value}>{bannerType.name}</option>
                    );
                  })}
                </select>
                {errors.banerType && (
                  <div className="ml-2 mt-1">
                    {errors.banerType && (
                      <div className="pl-1 text__left">
                        <span className="text-red">{errors.banerType}</span>
                      </div>
                    )}
                  </div>
                )}
                {bannerValue == 3 && (
                  <div className="form-group">
                    <label className="mb-2 text-gray">Link</label>
                    <input
                      type="text"
                      className="form-control"
                      required=""
                      placeholder="https://www.google.com"
                      value={link}
                      onChange={(e) => {
                        setLink(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            link: "Link is Required!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            link: "",
                          });
                        }
                      }}
                    />
                    {errors.link && (
                      <div className="ml-2 mt-1">
                        {errors.link && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.link}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

               
                {
                
                    <div className="form-group mt-4">
                    <label className="mb-2 text-gray">Banner Image</label>
                    <input
                      type="file"
                      className="form-control form-control-sm"
                      accept="image/jpg ,image/jpeg ,image/png"
                      required=""
                      onChange={HandleInputImage}
                    />
                    {errors.image && (
                      <div className="ml-2 mt-1">
                        {errors.image && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.image}</span>
                          </div>
                        )}
                      </div>
                    )}
                    {imagePath && (
                      <>
                        <img
                          height="70px"
                          width="70px"
                          alt="app"
                          src={imagePath}
                          style={{
                            boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                            // border: "2px solid #fff",
                            borderRadius: 10,
                            marginTop: 10,
                            float: "left",
                            objectFit: "cover",
                          }}
                        />
                      </>
                    )}
                  </div>
                  
                }
               
                <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { createNewBanner, editBanner })(BannerDialog);
