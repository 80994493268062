import { TablePagination, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { deleteBroadCast, getBroadCast } from "../../store/broadCast/action";
import $ from "jquery"
import noImage from "../../../src/assets/images/noImage.png";
import { OPEN_BROADCAST_DIALOG } from "../../store/broadCast/types";
import { Link } from "react-router-dom";

import { warning } from "../../util/Alert";
import { baseURL } from "../../util/Config";
import moment from "moment";
import BroadCastBannerDialogue from "../dialog/BroadCastBannerDialogue";
import { toast } from "react-toastify";
const TablePaginationActions = React.lazy(() => import("./TablePagination"));

const BroadcastBanner = (props) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { broadCast } = useSelector((state) => state.broadCast)


    useEffect(() => {
        dispatch(getBroadCast())
    }, [dispatch]);


    useEffect(() => {
        setData(broadCast);
    }, [broadCast]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    $(document).ready(function () {
        $("img").bind("error", function () {
            $(this).attr("src", noImage);
        });
    });

    const handleOpen = () => {


        dispatch({ type: OPEN_BROADCAST_DIALOG });

    };

    const handleDelete = (bannerId) => {
        const data = warning();
        data
            .then((isDeleted) => {
                if (isDeleted) {

                    props.deleteBroadCast(bannerId);
                    toast.success("Broadcast banner has been deleted!");
                    // alert(`Broadcast banner has been deleted!`);
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <>

            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className="mb-3 text-white">Broad Cast Banner</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard" className="text-danger">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Banner
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div className="card-header pb-0">
                            <div className="row my-3">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                                    <button
                                        type="button"
                                        className="btn waves-effect waves-light btn-danger btn-sm float-left"
                                        onClick={handleOpen}
                                        id="bannerDialog"
                                    >
                                        <i className="fa fa-plus"></i>
                                        <span className="icon_margin">New</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body card-overflow">
                            <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

                            <table class="table table-striped">
                                <thead className="text-center">
                                    <tr>
                                        <th>No.</th>
                                        <th>Image</th>
                                        <th>Created At</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {data.length > 0 ? (
                                        (rowsPerPage > 0
                                            ? data.slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            : data
                                        ).map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td >
                                                        <img
                                                            height="70px"
                                                            width="100px"
                                                            alt="app"
                                                            src={data?.image ? baseURL + data?.image : noImage}
                                                            style={{
                                                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                                border: "2px solid #fff",
                                                                borderRadius: 10,
                                                                display: "block",
                                                                // background: "transparent",
                                                                // backgroundColor: "transparent"
                                                            }}
                                                            className="mx-auto"
                                                        />
                                                    </td>


                                                    <td>{moment(data?.createdAt).format("DD/MM/YYYY")}</td>
                                                    <td>
                                                        <Tooltip title="Delete">
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() => handleDelete(data?._id)}
                                                            >
                                                                <i className="fas fa-trash-alt fa-lg"></i>
                                                            </button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="6" align="center">
                                                Nothing to show!!
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <TablePagination
                                id="pagination"
                                component="div"
                                rowsPerPageOptions={[
                                    5,
                                    10,
                                    25,
                                    100,
                                    { label: "All", value: -1 },
                                ]}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { "aria-label": "rows per page" },
                                    native: true,
                                }}
                                classes="menuItem"
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <BroadCastBannerDialogue />
        </>
    )
}
export default connect(null, { getBroadCast, deleteBroadCast })(
    BroadcastBanner
);