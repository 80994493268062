import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCategory } from "../../store/giftCategory/action";
import { baseURL } from "../../util/Config";
import { CLOSE_MP4_GIFT_DIALOG } from "../../store/gift/types";
// react dropzone
import ReactDropzone from "react-dropzone";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { createNewGift, editGift } from "../../store/gift/action";

const AddMp4Dialogue = (props) => {
  const dispatch = useDispatch();
  const {
    dialog2: open,
    dialogData2,
    gift,
  } = useSelector((state) => state.gift);
  const categories = useSelector((state) => state.giftCategory.giftCategory);

  const [images, setImages] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [mongoId, setMongoId] = useState("");
  const [coin, setCoin] = useState(0);
  const [category, setCategory] = useState("");
  const [image, setImage] = useState();

  const [errors, setError] = useState({
    coin: "",
    image: "",
    category: "",
  });

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  useEffect(() => {
    if (dialogData2) {
      setMongoId(dialogData2._id);
      setCoin(dialogData2.coin);
      setCategory(dialogData2.category);
      setImagePath(baseURL + dialogData2.video);
    }
  }, [dialogData2]);

  useEffect(
    () => () => {
      setError({
        coin: "",
        image: "",
        category: "",
      });
      setMongoId("");
      setCoin(0);
      setCategory("");
      setImages([]);
      setImageData(null);
      setImagePath(null);
    },
    [open]
  );

  const onPreviewDrop = (files) => {
    setError({ ...errors, image: "" });

    files.forEach((file, index) => {
      Object.assign(file, { preview: URL.createObjectURL(file) });
      setImages((prevImages) => prevImages.concat(file));
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (!coin || !category) {
      const errors = {};

      if (!coin) {
        errors.coin = "Coin can't be a blank!";
      }
      if (!category) {
        errors.category = "Category can't be a blank!";
      }
      if (images.length === 0) {
        errors.image = "Please select an Image!";
      }

      return setError({ ...errors });
    }

    if (mongoId) {
      if (!imageData && !imagePath) {
        return setError({ ...errors, image: "Please select an Image!" });
      }
    } else {
      if (images.length === 0) {
        return setError({ ...errors, image: "Please select an Image!" });
      }
    }

    if (image) {
      formData.append("svgaImage", image);
    }

    formData.append("coin", coin);
    formData.append("category", category);

    if (mongoId) {
      formData.append("image", imageData);
    } else {
      for (let i = 0; i < images.length; i++) {
        formData.append("image", images[i]);
      }
    }

    if (mongoId) {
      dispatch(editGift(formData, mongoId));
      closePopup();
    } else {
      closePopup();
      dispatch(createNewGift(formData, category));
    }
  };

  const closePopup = () => {
    dispatch({ type: CLOSE_MP4_GIFT_DIALOG });
  };
  const removeImage = (file) => {
    if (file.preview) {
      const image = images.filter((ele) => {
        return ele.preview !== file.preview;
      });
      setImages(image);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> MP4 </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div class="modal-body pt-1 px-1 pb-3">
            <div class="d-flex flex-column">
              <form>
                <div class="form-group">
                  <label className="mb-2 text-gray">Coin</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Magic"
                    required
                    value={coin}
                    onChange={(e) => {
                      setCoin(e.target.value);

                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          coin: "coin can't be a blank!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          coin: "",
                        });
                      }
                    }}
                  />
                  {errors.coin && (
                    <div className="ml-2 mt-1">
                      {errors.coin && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.coin}</span>
                        </div>
                      )}
                    </div>
                  )}

                  <label className="mb-2 mt-2 text-gray">Category</label>

                  <select
                    class="form-select form-control"
                    aria-label="Default select example"
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);

                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          category: "Please select a Category!",
                        });
                      } else if (e.target.value == "Category") {
                        return setError({
                          ...errors,
                          category: "Please select a Category!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          category: "",
                        });
                      }
                    }}
                  >
                    <option selected>Category</option>
                    {categories.map((category) => {
                      return (
                        <option value={category._id}>{category.name}</option>
                      );
                    })}
                  </select>
                  {errors.category && (
                    <div className="ml-2 mt-1">
                      {errors.category && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.category}</span>
                        </div>
                      )}
                    </div>
                  )}

                  <div>
                    <div className="row mt-4">
                      <div className="">
                        <label
                          className="form-control-label"
                          for="input-username"
                        >
                          Select (Multiple) .mp4
                        </label>

                        <>
                          <ReactDropzone
                            onDrop={(acceptedFiles) =>
                              onPreviewDrop(acceptedFiles)
                            }
                            accept=".mp4"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <div
                                    style={{
                                      height: 130,
                                      width: 130,
                                      border: "2px dashed gray",
                                      textAlign: "center",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <i
                                      className="fas fa-plus"
                                      style={{ paddingTop: 30, fontSize: 70 }}
                                    ></i>
                                  </div>
                                </div>
                              </section>
                            )}
                          </ReactDropzone>

                          {errors.image && (
                            <div className="ml-2 mt-1">
                              {errors.image && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.image}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      </div>
                      <div className=" mt-4">
                        {images.length > 0 && (
                          <>
                            {images.length > 0 && (
                              <>
                                {images?.map((file, index) => {
                                  return (
                                    <>
                                      <video
                                        height="100px"
                                        width="100px"
                                        alt="app"
                                        src={file.preview}
                                        autoPlay
                                        muted
                                        style={{
                                          boxShadow:
                                            "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                          border: "2px solid #fff",
                                          borderRadius: 10,
                                          marginTop: 10,
                                          float: "left",
                                          objectFit: "contain",
                                          marginRight: 15,
                                        }}
                                      />
                                      <div
                                        className="img-container"
                                        style={{
                                          display: "inline",
                                          position: "relative",
                                          float: "left",
                                        }}
                                      >
                                        <i
                                          className="fas fa-times-circle text-danger"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            top: "4px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => removeImage(file)}
                                        ></i>
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddMp4Dialogue;
