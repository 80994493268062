export const GET_BD = "GET_BD";

export const CREATE_NEW_BD = "CREATE_NEW_BD";

export const OPEN_BD_DIALOG = "OPEN_BD_DIALOG";

export const CLOSE_BD_DIALOG = "CLOSE_BD_DIALOG";

export const EDIT_BD = "EDIT_BD";

export const ENABLE_DISABLE_BD = "ENABLE_DISABLE_BD";

export const GET_BD_WISE_AGENCY = "GET_BD_WISE_AGENCY";

export const REDEEM_ENDABLED_SWITCH_BD = "REDEEM_ENDABLED_SWITCH_BD";
