import React, { useEffect } from "react";

// routing
import { NavLink as Link } from "react-router-dom";

// alert
import { warning } from "../../util/Alert";

// redux
import { useDispatch, useSelector } from "react-redux";

// types
import { UNSET_ADMIN } from "../../store/admin/types";
import { ReactComponent as Settlement } from "../../assets/images/settlement.svg";
import { ReactComponent as HostSettleMent } from "../../assets/images/hostSettlement.svg";
import { ReactComponent as BdSettlement } from "../../assets/images/bdSettlement.svg";



//MUI
import { makeStyles } from "@material-ui/core";

// jquery
import $ from "jquery";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  navLink: {
    "&.active": {
      color: "#E85382 !important",
      fontWeight: 500,
      fontSize: 16,
    },
    "&.active span": {
      color: "#E85382 !important",
      fontWeight: 900,
    },
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    const data = warning();
    data.then((isLogout) => {
      if (isLogout) {
        dispatch({ type: UNSET_ADMIN });
        history.push("/");
      }
    });
  };
  const { admin } = useSelector((state) => state.admin);
  useEffect(() => {
    $("").addClass("submenu-margin");
  }, []);
  return (
    <>
      <div class="page-sidebar">
        <Link to="/admin/dashboard">
          <span className="logo text-danger">ATM-Live</span>
        </Link>
        {
          admin?._id === "66d0301769062668bdb4e8a5" ? (
            <>
              <ul class="list-unstyled accordion-menu">
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Dashboard"
                >
                  <Link to="/admin/dashboard" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="activity"></i>
                    </span>
                    Dashboard
                  </Link>
                </li>

                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Subadmin">
                  <Link to="/admin/subadmin" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="gitlab"></i>
                    </span>
                    Sub Admin
                  </Link>
                </li>

                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Banner">
                  <Link to="/admin/banner" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="image"></i>
                    </span>
                    Banner
                  </Link>
                </li>

                <li data-bs-toggle="tooltip" data-bs-placement="top" title="broadCast">
                  <Link to="/admin/broadCast" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="book"></i>
                    </span>
                    Broad Cast Banner
                  </Link>
                </li>

                <li data-bs-toggle="tooltip" data-bs-placement="top" title="bd">
                  <Link to="/admin/bd" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="user-plus"></i>
                    </span>
                    BD
                  </Link>
                </li>

                {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="Theme">
                  <Link to="/admin/agency" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="image"></i>
                    </span>
                    Agency
                  </Link>
                </li> */}

                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="User"
                  className="pointer-cursor"
                >
                  <a
                    href={() => false}
                    className="add-collapse-margin"
                    style={{ marginLeft: 0 }}
                  >
                    <span className="sidenav__icon">
                      <i data-feather="users"></i>
                    </span>
                    User
                    <i class="fas fa-chevron-right dropdown-icon"></i>
                  </a>
                  <ul class="">
                    <li>
                      <Link to="/admin/user" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Real
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/fakeUser" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Fake
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="User"
                  className="pointer-cursor"
                >
                  <a
                    href={() => false}
                    className="add-collapse-margin"
                    style={{ marginLeft: 0 }}
                  >
                    <span className="sidenav__icon">
                      <i data-feather="users"></i>
                    </span>
                    Agency
                    <i class="fas fa-chevron-right dropdown-icon"></i>
                  </a>
                  <ul class="">
                    <li>
                      <Link to="/admin/verified" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Verfied
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/unverified" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Unverfied
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="User"
                  className="pointer-cursor"
                >
                  <a
                    href={() => false}
                    className="add-collapse-margin"
                    style={{ marginLeft: 0 }}
                  >
                    <span className="sidenav__icon">
                      <Settlement />
                    </span>
                    Agency Settlement
                    <i class="fas fa-chevron-right dropdown-icon"></i>
                  </a>
                  <ul class="">
                    {/* <li>
                <Link
                  to="/admin/allSettlement"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>All Settlement
                </Link>
              </li> */}
                    <li>
                      <Link to="/admin/solved" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Solved
                      </Link>
                    </li>

                    <li>
                      <Link to="/admin/pending" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Pending
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="User"
                  className="pointer-cursor"
                >
                  <a
                    href={() => false}
                    className="add-collapse-margin"
                    style={{ marginLeft: 0 }}
                  >
                    <span className="sidenav__icon">
                      < HostSettleMent />
                    </span>
                    Host Settlement
                    <i class="fas fa-chevron-right dropdown-icon"></i>
                  </a>
                  <ul class="">
                    {/* <li>
                <Link
                  to="/admin/hostallSettlement"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>All Settlement
                </Link>
              </li> */}
                    <li>
                      <Link to="/admin/hostsolved" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Solved
                      </Link>
                    </li>

                    <li>
                      <Link to="/admin/hostpending" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Pending
                      </Link>
                    </li>
                  </ul>
                </li>
                
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="User"
                  className="pointer-cursor"
                >
                  <a
                    href={() => false}
                    className="add-collapse-margin"
                    style={{ marginLeft: 0 }}
                  >
                    <span className="sidenav__icon">
                      < BdSettlement />
                    </span>
                    Bd Settlement
                    <i class="fas fa-chevron-right dropdown-icon"></i>
                  </a>
                  <ul class="">
                    {/* <li>
                <Link
                  to="/admin/hostallSettlement"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>All Settlement
                </Link>
              </li> */}
                    <li>
                      <Link to="/admin/bdsolved" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Solved
                      </Link>
                    </li>

                    <li>
                      <Link to="/admin/bdpending" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Pending
                      </Link>
                    </li>
                  </ul>
                </li>
                

              
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Coin Seller"
                >
                  <Link to="/admin/hostRequest" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="user-check"></i>
                    </span>
                    Host Request
                  </Link>
                </li>

                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Coin Seller"
                >
                  <Link to="/admin/coinSeller" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="dollar-sign"></i>
                    </span>
                    Coin Seller
                  </Link>
                </li>

                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Redeem"
                  className="pointer-cursor"
                >
                  <Link
                    to="/admin/userRedeemRequest"
                    className={`${classes.navLink}`}
                  >
                    <span className="sidenav__icon">
                      <i data-feather="key"></i>
                    </span>
                    User Redeem
                  </Link>
                </li>

                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Plan"
                  className="pointer-cursor"
                >
                  <a
                    href={() => false}
                    className="add-collapse-margin"
                    style={{ marginLeft: 0 }}
                  >
                    <span className="sidenav__icon">
                      <i data-feather="layout"></i>
                    </span>
                    Plan
                    <i class="fas fa-chevron-right dropdown-icon"></i>
                  </a>
                  <ul class="">
                    <li>
                      <Link to="/admin/coinPlan" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Coin Plan
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/vipPlan" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>VIP Plan
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Purchase Plan History"
                  className="pointer-cursor"
                >
                  <a
                    href={() => false}
                    className="add-collapse-margin"
                    style={{ marginLeft: 0 }}
                  >
                    <span className="sidenav__icon">
                      <i data-feather="clock"></i>
                    </span>
                    Plan History
                    <i class="fas fa-chevron-right dropdown-icon"></i>
                  </a>
                  <ul class="">
                    <li>
                      <Link
                        to="/admin/coinPlan/history"
                        className={`${classes.navLink}`}
                      >
                        <i class="far fa-circle"></i>Coin Plan
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/vipPlan/history"
                        className={`${classes.navLink}`}
                      >
                        <i class="far fa-circle"></i>VIP Plan
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Game History"
                >
                  <Link
                    to="/admin/game"
                    className={`${classes.navLink}`}
                    style={{ display: "flex" }}
                  >
                    <span
                      className="sidenav__icon"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <i class="far fa-gamepad" style={{ fontSize: "23px" }}></i>
                    </span>
                    <span> Game</span>
                  </Link>
                </li>
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Game History"
                >
                  <Link to="/admin/gameHistory" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="hash"></i>
                    </span>
                    Game History
                  </Link>
                </li>
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Gift"
                  className="pointer-cursor"
                >
                  <a
                    href={() => false}
                    className="add-collapse-margin"
                    style={{ marginLeft: 0 }}
                  >
                    <span className="sidenav__icon">
                      <i data-feather="gift"></i>
                    </span>
                    Gift
                    <i class="fas fa-chevron-right dropdown-icon"></i>
                  </a>
                  <ul class="">
                    <li>
                      <Link
                        to="/admin/giftCategory"
                        className={`${classes.navLink}`}
                        onClick={() => localStorage.removeItem("GiftClick")}
                      >
                        <i class="far fa-circle"></i>Category
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/gift"
                        className={`${classes.navLink}`}
                        onClick={() => localStorage.setItem("GiftClick", true)}
                      >
                        <i class="far fa-circle"></i>Gift
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Game History"
          >
            <Link
              to="/admin/reaction"
              className={`${classes.navLink}`}
              style={{ display: "flex" }}
            >
              <span
                className="sidenav__icon"
                style={{ display: "flex", alignItems: "center" }}
              >
                <i class="far fa-smile-wink" style={{ fontSize: "23px" }} />
              </span>
              Reaction
            </Link>
          </li> */}


                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Gift"
                  className="pointer-cursor"
                >
                  <a
                    href={() => false}
                    className="add-collapse-margin"
                    style={{ marginLeft: 0 }}
                  >
                    <span className="sidenav__icon">
                      <i data-feather="loader"></i>
                    </span>
                    Store
                    <i className="fas fa-chevron-right dropdown-icon"></i>
                  </a>
                  <ul className="">
                    <li>
                      <Link
                        to="/admin/entryEffect"
                        className={`${classes.navLink}`}
                        onClick={() => localStorage.removeItem("GiftClick")}
                      >
                        <i className="far fa-circle"></i>Entry Effect
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/avatarFrame"
                        className={`${classes.navLink}`}
                        onClick={() => localStorage.setItem("GiftClick", true)}
                      >
                        <i className="far fa-circle"></i>Avatar Frame
                      </Link>
                    </li>
                  </ul>
                </li>

                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Sticker">
                  <Link to="/admin/sticker" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="anchor"></i>
                    </span>
                    Sticker
                  </Link>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Theme">
                  <Link to="/admin/theme" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="image"></i>
                    </span>
                    Theme
                  </Link>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Song">
                  <Link to="/admin/song" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="music"></i>
                    </span>
                    Song
                  </Link>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Hashtag">
                  <Link to="/admin/hashtag" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="hash"></i>
                    </span>
                    Hashtag
                  </Link>
                </li>
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Dashboard"
                >
                  <Link to="/admin/comment" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="message-circle"></i>
                    </span>
                    Fake Comment
                  </Link>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Level">
                  <Link to="/admin/level" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="bar-chart"></i>
                    </span>
                    Level
                  </Link>
                </li>

                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Commission"
                  className="pointer-cursor"
                >
                  <Link to="/admin/commission" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="database"></i>
                    </span>
                    Commission
                  </Link>
                </li>

                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Post"
                  className="pointer-cursor"
                >
                  <a
                    href={() => false}
                    className="add-collapse-margin"
                    style={{ marginLeft: 0 }}
                  >
                    <span className="sidenav__icon">
                      <i data-feather="maximize"></i>
                    </span>
                    Post
                    <i class="fas fa-chevron-right dropdown-icon"></i>
                  </a>
                  <ul class="">
                    <li>
                      <Link to="/admin/post" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Real
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/post/fake" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Fake
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Video"
                  className="pointer-cursor"
                >
                  <a
                    href={() => false}
                    className="add-collapse-margin"
                    style={{ marginLeft: 0 }}
                  >
                    <span className="sidenav__icon">
                      <i data-feather="film"></i>
                    </span>
                    Video
                    <i class="fas fa-chevron-right dropdown-icon"></i>
                  </a>
                  <ul class="">
                    <li>
                      <Link to="/admin/video" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Real
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/video/fake" className={`${classes.navLink}`}>
                        <i class="far fa-circle"></i>Fake
                      </Link>
                    </li>
                  </ul>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Video">
                  <Link to="/admin/reportedUser" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="flag"></i>
                    </span>
                    Reported User
                  </Link>
                </li>
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Complain"
                  className="pointer-cursor"
                >
                  <a
                    href={() => false}
                    className="add-collapse-margin"
                    style={{ marginLeft: 0 }}
                  >
                    <span className="sidenav__icon">
                      <i data-feather="help-circle"></i>
                    </span>
                    Complain
                    <i class="fas fa-chevron-right dropdown-icon"></i>
                  </a>
                  <ul class="">
                    <li>
                      <Link
                        to="/admin/pendingComplain"
                        className={`${classes.navLink}`}
                      >
                        <i class="far fa-circle"></i>Pending
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/solvedComplain"
                        className={`${classes.navLink}`}
                      >
                        <i class="far fa-circle"></i>Solved
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Google Ad"
                >
                  <Link to="/admin/advertisement" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="book"></i>
                    </span>
                    Google Ad
                  </Link>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Setting">
                  <Link to="/admin/Setting" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="settings"></i>
                    </span>
                    Setting
                  </Link>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Profile">
                  <Link to="/admin/adminProfile" className={`${classes.navLink}`}>
                    <span className="sidenav__icon">
                      <i data-feather="user"></i>
                    </span>
                    Profile
                  </Link>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Logout">
                  <a
                    href={() => false}
                    onClick={handleLogout}
                    className="add-collapse-margin"
                  >
                    <i data-feather="log-out"></i>Logout
                  </a>
                </li>
              </ul>
            </>
          ) : (
            <ul className="list-unstyled accordion-menu">
              <li
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Dashboard"
              >
                <Link to="/admin/subdashboard" className={`${classes.navLink}`}>
                  <span className="sidenav__icon">
                    <i data-feather="activity"></i>
                  </span>
                  Dashboard
                </Link>
              </li>
              {admin?.roles?.map((data) => {
                return (
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="User"
                  >
                    <Link to={data?.path} className={`${classes.navLink}`}>
                      <i className="fas fa-chevron-right me-4"></i>
                      <span className="ms-2">{data?.name}</span>
                    </Link>
                  </li>
                );
              })}
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="Logout">
                <a
                  href={() => false}
                  onClick={handleLogout}
                  className="add-collapse-margin"
                >
                  <i data-feather="log-out"></i>Logout
                </a>
              </li>
            </ul>
          )
        }

        <a
          href={() => false}
          id="sidebar-collapsed-toggle"
          style={{ opacity: 0, pointerEvents: "none" }}
        >
          <i data-feather="arrow-right"></i>
        </a>
      </div>
    </>
  );
};
export default Navbar;
