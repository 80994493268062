import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
//MUI
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//action
import { createNewAgency, editAgency, getUnVerifiedAgency, handleVerifiedAgency } from "../../store/agency/action";
import Male from "../../assets/images/male.png";
import { CLOSE_AGENCY_DIALOG, CLOSE_AGENCY_VERIFIED_DIALOG } from "../../store/agency/type";
import $ from "jquery";
import { getCoinSellerUniqueId } from "../../store/coinSeller/action";
// import { getBd } from "../../store/bd/action";
// import { getCoinSellerUniqueId } from "../../store/coinSeller/action";

const AgencyVerifiedDialog = (props) => {
    const dispatch = useDispatch();


    const {
        dialog1: open,
        dialogData1,
        agency,
    } = useSelector((state) => state.agency);


    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(handleVerifiedAgency(dialogData1?._id))
        closePopup();
    };

    const closePopup = () => {
        dispatch({ type: CLOSE_AGENCY_VERIFIED_DIALOG });
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            ":hover": {
                backgroundColor: "#BBDEFB",
                color: "#000",
            },
        }),
    };

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="responsive-dialog-title"
                onClose={closePopup}
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth
                sx={{ maxWidth: "400px" }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <span className="text-danger font-weight-bold h4"> Would you like to approve a Unverified agency? </span>
                </DialogTitle>

                <IconButton
                    style={{
                        position: "absolute",
                        right: 0,
                        top : "5px"
                    }}
                >
                    <Tooltip title="Close">
                        <Cancel className="text-danger" onClick={closePopup} />
                    </Tooltip>
                </IconButton>
                <DialogContent>
                    <div className="modal-body pt-1 px-1 pb-3">
                        <div className="d-flex flex-column">
                            <form>
                                <button
                                    type="button"
                                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                                    onClick={closePopup}
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-round float__right btn-danger"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default connect(null, { createNewAgency, editAgency })(AgencyVerifiedDialog);
