import { CLOSE_BD_DIALOG, CREATE_NEW_BD, EDIT_BD, ENABLE_DISABLE_BD, GET_BD, GET_BD_WISE_AGENCY, OPEN_BD_DIALOG, REDEEM_ENDABLED_SWITCH_BD } from "./types";

  
  const initialState = {
    bd: [],
    total: 0,
    agencyDropdown: [],
    dialog: false,
    dialogData: null,
  };
  
  const bdReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_BD:
        return {
          ...state,
          bd: action.payload.data,
          total: action.payload.total,
        };
  
      case CREATE_NEW_BD:
        const data = [...state.bd];
        data.unshift(action.payload);
        return {
          ...state,
          bd: data,
        };
  
      case EDIT_BD:
        return {
          ...state,
          bd: state.bd.map((bd) => {
            if (bd._id === action.payload.id) return action.payload.data;
            else return bd;
          }),
        };
  
      case OPEN_BD_DIALOG:
        return {
          ...state,
          dialog: true,
          dialogData: action.payload || null,
        };
      case CLOSE_BD_DIALOG:
        return {
          ...state,
          dialog: false,
          dialogData: null,
        };
      case ENABLE_DISABLE_BD:
        return {
          ...state,
          bd: state.bd.map((bd) => {
            if (bd?._id === action?.payload?._id)
              return {
                ...bd,
                isActive: action?.payload?.isActive,
              };
            else return bd;
          }),
        };
      case REDEEM_ENDABLED_SWITCH_BD:
        return {
          ...state,
          bd: state.bd.map((bd) => {
            if (bd?._id === action?.payload?._id)
              return {
                ...bd,
                redeemEnable: action?.payload?.redeemEnable,
              };
            else return bd;
          }),
        };
      case GET_BD_WISE_AGENCY:
        return {
          ...state,
          bdWiseAgency: action.payload.data,
        };
      default:
        return state;
    }
  };
  
  export default bdReducer;
  