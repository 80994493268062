import { Toast } from "../../util/Toast";
import { apiInstanceFetch } from "../../util/api";
 

import axios from "axios";
import { CLOSE_BD_DIALOG, CREATE_NEW_BD, EDIT_BD, ENABLE_DISABLE_BD, GET_BD, GET_BD_WISE_AGENCY, REDEEM_ENDABLED_SWITCH_BD } from "./types";

export const getBd = (start, limit) => (dispatch) => {
  apiInstanceFetch
    .get(`bd/index?start=${start}&limit=${limit}`)
    .then((res) => {
      dispatch({ type: GET_BD, payload: res });
    })
    .catch((error) => console.log(error));
};
export const getBdWiseAgency = (id, start, limit, search) => (dispatch) => {
  apiInstanceFetch
    .get(`bd/bdWiseAgency?bdId=${id}`)
    .then((res) => {
      dispatch({
        type: GET_BD_WISE_AGENCY,
        payload: { data: res?.data },
      });
    })
    .catch((error) => console.log(error));
};

export const createNewBd = (formData) => (dispatch) => {
  axios
    .post("bd/store", formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: CREATE_NEW_BD, payload: res.data.data });
        dispatch({ type: CLOSE_BD_DIALOG });
        Toast("success", "Agency Create Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
export const editBd = (formData, id) => (dispatch) => {
  axios
    .patch(`bd/update?bdId=${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_BD,
          payload: { data: res.data.data, id },
        });
        dispatch({ type: CLOSE_BD_DIALOG });
        Toast("success", "Agency Update Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const enableDisableBD = (id) => (dispatch) => {
  axios
    .patch(`bd/activeOrNot?bdId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ENABLE_DISABLE_BD, payload: res.data.data });
        Toast(
          "success",
          `${
            res.data?.data?.isActive === true
              ? "Active Successfully"
              : "InActive Successfully"
          }`
        );
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
export const redeemEnableBd = (id) => (dispatch) => {
  axios
    .patch(`bdRedeem/handleRedeem?bdId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: REDEEM_ENDABLED_SWITCH_BD, payload: res.data.data });
        Toast(
          "success",
          `${
            res.data?.data?.redeemEnable === true
              ? "Enabled Successfully"
              : "Disabled Successfully"
          }`
        );
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
