import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../store/admin/action";

const SubAdminDashboard = () => {
    const { admin } = useSelector((state) => state.admin);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProfile());
    }, []);

    return (
        <div>
            <h1 className="text-white">Welcome</h1>

            <div className="row">
                {admin?.roles?.map((data) => {
                    return (
                        <>
                            <div class="col-lg-3 col-md-4 col-sm-6">
                                <div class="card stats-card">
                                    <div class="card-body pointer-cursor">
                                        <div class="stats-info">
                                            <p class="stats-text">{data?.name}</p>
                                        </div>
                                        <div class="stats-icon change-danger">
                                            <i class="material-icons">people_alt</i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
        </div>
    );
};

export default SubAdminDashboard;
